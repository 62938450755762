/**
*****
*** Base & Utils Style
*****
**/
@import "./fonts/stylesheet.css";
body {
  margin: 0;
  font-family: "Lato", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.percentInput input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.text-success {
  color: #28a745;
}
.text-danger {
  color: #dc3545;
}
.text-black {
  color: #000;
}
.d-flex {
  display: flex;
}
.d-block {
  display: block;
}
.d-inline {
  display: inline;
}
.color-pink {
  color: #d72d83 !important;
}
.card-wrapper {
  text-align: center;
  width: 100%;
}

.view-show {
  display: block;
  width: 100%;
}

.view-hide {
  display: none;
}

.float-right {
  float: right;
}

.rounded-0 {
  border-radius: 0 !important;
}

.no-decoration:-webkit-any-link,
a:-webkit-any-link {
  color: rgba(0, 0, 0, 0.87);
}

.no-decoration:-webkit-any-link {
  text-decoration: none;
}

.range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  background: #d3d3d3;
  outline: none;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #000;
    cursor: pointer;
    border-radius: 50%;
  }
  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #000;
    cursor: pointer;
  }
}
/*
*****
*** Spacing
*****
*/

.m-t-50 {
  margin-top: 50px;
}

.m-r-1 {
  margin-right: 0.25rem;
}

.m-r-2 {
  margin-right: 0.5rem;
}

.m-r-3 {
  margin-right: 0.75rem;
}

.m-r-4 {
  margin-right: 1rem;
}
.m-l-8 {
  margin-left: 2rem;
}

.m-t-4 {
  margin-top: 1rem;
}

.m-b-3 {
  margin-bottom: 0.75rem;
}

.m-b-5 {
  margin-bottom: 2rem;
}

/**
*****
*** Header
*****
**/
.MuiAppBar-root {
  &.MuiAppBar-colorPrimary {
    background-color: #1c1524;
    box-shadow: none;
  }
  .drawer-open {
    display: none;
    color: #fff;
    margin-left: -8px;
    margin-right: 2rem;
  }
  &[class*="makeStyles-appBarShift"] {
    .drawer-open {
      display: block;
    }
  }
  &:not([class*="makeStyles-appBarShift"]) {
    + .MuiDrawer-root {
      .MuiPaper-root {
        width: 4rem;
        @media screen and (max-width: 1279px) {
          width: 16rem;
          left: 0;
        }
        @media screen and (max-width: 767px) {
          width: 4rem;
          left: 0;
        }
      }
    }
  }
}

/**
*****
*** Drawer Menu List
*****
**/
.MuiDrawer-root {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;

  div[class*="makeStyles-search"] {
    .MuiIconButton-root {
      color: #fff;
    }
  }
  .drawer-menu-list {
    margin: auto 0;
    &.MuiList-root {
      padding: 25px 0;
      .MuiListItem-root {
        // border-bottom: 1px solid #8c8c8c;
        padding: 0;
        &:first-child {
          // border-top: 1px solid #fff;
        }
        &:last-child {
          a {
            border-bottom: none;
          }
        }
        .icon {
          // padding-right: 35px;
          // box-sizing: border-box;
          // max-width: 81px;
          // width: 100%;
        }
        a {
          box-sizing: border-box;
          height: 58px;
          padding: 0px 0px 0px 21px;
          opacity: 0.55;
          margin-left: 23px;
          border-top-left-radius: 29px;
          border-bottom-left-radius: 29px;
          // border-top: 1px solid rgba(255, 255, 255, 0.5);
          &.active,
          &:hover {
            background: linear-gradient(131deg, #4B478D, #CA2E86);
            opacity: 1;
          }
        }
        .MuiListItemIcon-root {
          padding-left: 4px;
        }
      }
    }
  }
  .MuiPaper-root {
    .MuiList-root {
      .MuiButtonBase-root {
        a {
          display: grid;
          grid-template-columns: minmax(auto, 81px) auto;
          width: 100%;
          align-items: center;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}

/**
*****
*** Form Control Style
*****
**/
form[name="form-layout"] {
  .gift-header {
    p {
      color: black;
    }
  }
  * {
    font-family: "Lato", sans-serif !important;
    font-size: 1rem;
  }
  label {
    color: #fff;
    .MuiFormLabel-asterisk {
      color: #fff;
    }
    &.Mui-focused {
      color: #fff;
      .MuiFormLabel-asterisk {
        color: #fff;
      }
    }
    + div {
      span {
        font-size: 0.875rem;
      }
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 2rem;
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        background-color: #1c1524;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      background-color: #fff;
    }
  }
  .MuiCheckbox-root {
    color: #fff;
  }
  .MuiButtonBase-root:not(.MuiCheckbox-root) {
    height: 56px;
    border-radius: 2rem;
    font-size: 1.125rem;
    padding-top: 7px;
    background-color: transparent;
    // background-color: #000;
    text-transform: capitalize;
    a {
      text-decoration: none;
    }
  }
  .MuiButtonBase-root {
    &.btn-billing {
      background-color: #fff;
      margin-top: 1rem;
      .MuiButton-label {
        color: #000;
      }
    }
    &.btn-select-plan {
      background-color: #d72d83;
      margin-bottom: 0.5rem;
      .MuiButton-label {
        color: #fff;
        justify-content: space-between;
        font-size: 15px;
        svg {
          font-size: 2rem;
        }
      }
    }
  }
  .custom-field {
    border-radius: 30px;
    border: 1.5px solid #fff;
    padding: 13px 16px;
    background-color: #1c1524;
    max-width: 440px;
    color: #fff;
    margin: 1rem auto 2rem;
    text-align: left;
    position: relative;
    @media screen and (max-width: 514px) {
      max-width: 300px;
    }
    .error {
      font-size: 30px;
      position: absolute;
      right: -41px;
      top: 5px;
    }
    label {
      font-size: 1.125rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
    }
    textarea {
      background-color: transparent;
      border: 0;
      width: 100%;
      font-size: 1.25rem;
      box-shadow: none;
      outline: 0;
      color: #fff;
      height: 90px;
      resize: none;
      &::placeholder {
        color: #fff;
      }
      &::-webkit-input-placeholder {
        color: #fff;
      }
      &::-ms-input-placeholder {
        color: #fff;
      }
    }
    select {
      background-color: transparent;
      border: 0;
      float: right;
      font-size: 20px;
      box-shadow: none;
      outline: 0;
      color: #fff;
      height: 30px;
      option {
        color: #fff;
      }
    }
    input {
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid #77737c;
      float: right;
      font-size: 20px;
      box-shadow: none;
      outline: 0;
      color: #fff;
      height: 30px;
      min-width: 220px;
      &::placeholder {
        font-size: 1rem;
        color: #fff;
      }
      &::-webkit-input-placeholder {
        font-size: 1rem;
        color: #fff;
      }
      &::-ms-input-placeholder {
        font-size: 1rem;
        color: #fff;
      }
    }
    > span {
      font-size: 1.125rem;
      color: #fff;
      @media screen and (max-width: 514px) {
        font-size: 0.875rem;
      }
      small {
        font-size: 15px;
        @media screen and (max-width: 514px) {
          font-size: 0.675rem;
        }
      }
    }
    &.custom-input {
      display: flex;
      input {
        background-color: transparent;
        border: 0;
        font-size: 1.25rem;
        box-shadow: none;
        outline: 0;
        color: #fff;
        height: 35px;
        min-width: 220px;
        line-height: normal;
        width: 100%;
        padding: 0;
        &::placeholder {
          font-size: 1.25rem;
          color: #fff;
        }
        &::-webkit-input-placeholder {
          font-size: 1.25rem;
          color: #fff;
        }
        &::-ms-input-placeholder {
          font-size: 1.25rem;
          color: #fff;
        }
      }
      &.custom-file {
        position: relative;
        input {
          width: 100%;
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }
        label {
          line-height: 1.8;
          width: 100%;
          justify-content: center;
        }
      }
    }
    &.custom-select {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 6px 6px 16px;
      input,
      > select {
        background-color: #fff;
        color: #000;
        height: 48px;
        width: 156px;
        border-radius: 30px;
        padding: 0 20px;
        option {
          color: #000;
        }
      }
      input {
        min-width: 100px;
        width: 116px;
        color: #000;
        &::placeholder {
          font-size: 1.25rem;
          color: #0a0a0a !important;
        }
        &::-webkit-input-placeholder {
          font-size: 1.25rem;
          color: #0a0a0a !important;
        }
        &::-ms-input-placeholder {
          font-size: 1.25rem;
          color: #0a0a0a !important;
        }
      }
    }
    &.static-info {
      display: flex;
      align-items: center;
      height: 30px;
      background-color: #fff;
      span {
        font-size: 15px;
        color: #000;
        a {
          font-size: 15px;
          color: #000;
          text-decoration: none;
        }
      }
    }
    &.custom-input-radio {
      border: 0;
      padding: 0;
      margin-top: 0;
      margin-bottom: 1.5rem;
      display: inline-flex;
      align-items: center;
      @media screen and (max-width: 514px) {
        max-width: 320px;
      }
      .check-input {
        position: relative;
        width: 50%;
        cursor: pointer;
        &:first-child {
          flex: 0 0 59%;
        }
        > input {
          position: absolute;
          width: 0;
          opacity: 0;
          min-width: auto;
          &:checked {
            + .radio-shape {
              border-color: #fff;
              background-color: #fff;
              + .radio-text {
                color: #fff;
              }
            }
          }
        }
        > .radio-shape {
          flex: 0 0 28px;
          border: 1px solid rgba(255, 255, 255, 0.5);
          display: inline-flex;
          margin-right: 23px;
          border-radius: 50%;
          height: 28px;
          @media screen and (max-width: 514px) {
            margin-right: 8px;
            height: 20px;
            flex: 0 0 20px;
          }
        }
        > .radio-text {
          color: rgba(255, 255, 255, 0.6);
          @media screen and (max-width: 514px) {
            font-size: 1rem;
          }
        }
      }
      &.radio-3 {
        width: 100%;
        margin-bottom: 5rem;
        text-align: center;
        .check-input {
          width: 33.33%;
          flex: 0 0 auto;
          justify-content: center;
        }
      }
    }
    &.custom-input-check {
      border: 0;
      padding: 0 1.5rem;
      margin-top: 0;
      display: inline-flex;
      align-items: center;
      @media screen and (max-width: 514px) {
        max-width: 320px;
      }
      .check-input {
        position: relative;
        width: 100%;
        cursor: pointer;
        > input {
          position: absolute;
          width: 0;
          opacity: 0;
          min-width: auto;
          &:checked {
            + .check-shape {
              border-color: #fff;
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                display: block;
                fill: #000;
                font-size: 1.5rem;
                @media screen and (max-width: 514px) {
                  font-size: 1.2rem;
                }
              }
              + .check-text {
                color: #fff;
              }
            }
          }
        }
        > .check-shape {
          flex: 0 0 30px;
          border: 1px solid rgba(255, 255, 255, 0.5);
          display: inline-flex;
          margin-right: 12px;
          height: 30px;
          svg {
            display: none;
          }
          @media screen and (max-width: 514px) {
            margin-right: 8px;
            height: 20px;
            flex: 0 0 20px;
          }
        }
        > .check-text {
          color: rgba(255, 255, 255, 0.6);
          font-size: 1.125rem;
          @media screen and (max-width: 514px) {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
  .custom-label {
    width: 100%;
    text-align: left;
    color: #fff;
    font-size: 1.125rem;
    display: inline-block;
    padding-left: 30px;
    @media screen and (max-width: 514px) {
      max-width: 300px;
      padding-left: 0;
      font-size: 1rem;
    }
  }
  .custom-button {
    background: transparent !important;
    box-shadow: none;
    color: #fff;
    .MuiButton-label {
      font-size: 2.25rem;
      line-height: normal;
    }
  }

  .custom-button-active {
    background: #fff !important;
    box-shadow: none;
    .MuiButton-label {
      color: black !important;
      font-size: 2.25rem;
      line-height: normal;
    }
  }
  .custom-button-text {
    color: #fff;
    line-height: normal;
    background-color: transparent !important;
    border-radius: 0 !important;
    box-shadow: none;
    padding: 6px 1.25rem;
    height: auto !important;
    span {
      font-size: 29px !important;
      font-weight: 400;
    }
  }
  .custom-white {
    background-color: #fff;
    height: 56px;
    border-radius: 2rem;
    color: #000;
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: none;
    min-width: 150px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .custom-button-gradiant {
    background: rgb(175, 31, 112);
    background: linear-gradient(90deg, #af1f70 0%, #333fc2 100%);
    font-size: 1.5rem !important;
    min-width: 150px;
    &.margin-bottom-20 {
      margin-bottom: 20px;
    }
  }
}

/**
*****
*** After Login Style
*****
**/

.card-container {
  max-width: 100%;

  /**
  ***
  *** Charity List
  ***
  **/
  .charity-list {
    display: block;
    margin: 0 auto;
    padding-top: 1rem;
    max-width: 442px;
    .charity-list-items {
      width: 50%;
      display: inline-flex;
      padding: 15px;
      a {
        display: inline-flex;
        img {
          width: 100%;
        }
      }
    }
  }

  /**
  ***
  *** Create Gift Card
  ***
  **/
  .card-container {
    text-align: center;
    width: 100%;
  }

  .addCardForm {
    width: "100%";
    margin-top: 24px;
    max-width: 440px;
    margin: 0 auto;
  }

  .drop-card,
  .campaigns-card,
  .gift-card {
    height: 255px;
    width: 400px;
    background-color: #fefefe;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    .gift-header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 12px;
      > div {
        display: flex;
        align-items: center;
        > img {
          border-radius: 50%;
          margin-right: 1rem;
          + span {
            font-size: 22px;
          }
        }
      }
    }
    .gift-body {
      background-color: rgba(0, 0, 0, 0.75);
      min-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      p {
        font-size: 25px;
        color: #fff;
        margin: 0;
      }
    }
    .gift-footer {
      padding: 40px 12px 15px;
      text-align: left;
      position: relative;
      label {
        position: relative;
        text-decoration: underline;
        font-size: 27px;
        .hidden-input-file {
          position: absolute;
          opacity: 0;
          top: 0;
        }
      }
    }
    &.front-view {
      .gift-header {
        padding: 0 12px;
        p {
          text-align: center;
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          margin-bottom: 5px;
        }
      }
      .gift-body {
        display: inline;
        text-align: center;
        button {
          width: 140px;
          height: 44px;
          border-radius: 22px;
          background: linear-gradient(135deg, #4b478d, #cf2d86);
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
          color: #000;
          border: 0;
          outline: 0;
          cursor: pointer;
        }
        h3 {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          margin: 0;
        }
        p {
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          color: #0a0a0a;
          padding: 2px 12px;
          word-break: break-all;
        }
        a {
          text-decoration: none;
          color: #0a0a0a;
        }
      }
      .gift-footer {
        padding-top: 0.5rem;
        text-align: center;
        background: linear-gradient(135deg, #4b478d, #cf2d86);
        margin: 5px 0;
        color: #fefefe;
      }
    }
  }

  .view-card {
    max-width: 440px;
    margin: 0 auto;
    text-align: left;
    @media screen and (max-width: 514px) {
      max-width: 330px;
    }
    .view-button {
      font-size: 20px;
      font-weight: 400;
      line-height: 23px;
      background-color: transparent;
      border: 0;
      color: #fff;
      margin: 20px 0 0;
      outline: 0;
      cursor: pointer;
    }
  }

  /**
  ***
  *** Gift Card Confirmation
  ***
  **/
  .confirm-gift-card {
    background-color: #fff;
    max-width: 450px;
    min-height: 650px;
    border-radius: 50px;
    padding: 40px 25px 50px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    @media screen and (max-width: 514px) {
      max-width: 320px;
      padding: 20px;
      border-radius: 30px;
    }
    @media screen and (max-width: 320px) {
      max-width: 250px;
    }
    .close {
      background-color: #000;
      width: 72px;
      height: 72px;
      position: absolute;
      top: 1rem;
      left: 1rem;
      color: #fff;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 514px) {
        width: 32px;
        height: 32px;
        top: 1.25rem;
      }
      svg {
        font-size: 46px;
        @media screen and (max-width: 514px) {
          font-size: 26px;
        }
      }
    }
    .confirm-title {
      font-size: 1.5rem;
      color: #000;
      margin: 0;
    }
    div[class*="makeStyles-confirmCard"] {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
      margin-top: 60px;
      @media screen and (max-width: 514px) {
        margin-top: 40px;
      }
      .confirm {
        &-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.2rem;
          position: relative;
          > img {
            border-radius: 50%;
          }
        }
        &-body {
          background-color: rgba(0, 0, 0, 0.75);
          min-height: 70px;
          align-items: center;
          justify-content: center;
          position: relative;

          h3 {
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            margin: 0;
            color: #0a0a0a;
          }
          p {
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            color: #0a0a0a;
          }
          a {
            text-decoration: none;
            color: #0a0a0a;
          }
        }
        &-footer {
          position: relative;
          text-align: center;
          height: 60px;
          background: linear-gradient(135deg, #4b478d, #cf2d86);
          margin: 10px 0;
          color: #fefefe;
        }
      }
      .back {
        background-color: rgba(0, 0, 0, 0.75);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 25px;
      }
    }
    .card-content {
      padding: 65px 0;
      font-size: 1.5rem;
      color: #000;
      p {
        text-align: left;
        padding: 0 35px;
      }
      center {
        p {
          text-align: center;
        }
      }
    }
    .custom-button {
      background: rgb(175, 31, 112);
      background: linear-gradient(90deg, #af1f70 0%, #333fc2 100%);
      font-size: 1.5rem;
      height: 56px;
      border-radius: 2rem;
      text-transform: capitalize;
      color: #fff;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1.5rem;
      min-width: 150px;
    }
    + .MuiGrid-root {
      .MuiButtonBase-root {
        height: 46px;
        border-radius: 2rem;
        font-size: 1.125rem;
        background-color: #fff;
        text-transform: capitalize;
        margin-top: 43px;
        .MuiButton-label {
          color: #000;
          font-size: 1.325rem;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }

  /**
  ***
  *** Personal Info Settings
  ***
  **/
  .avatar-action {
    border-bottom: 3px solid #979797;
    margin-bottom: 4.5rem;

    label {
      position: relative;
      width: 180px;
      height: 180px;
      font-size: 1.25rem;
      color: #000;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: #d8d8d8;
      border-radius: 50%;
      border: 1px solid #9b9b9b;
      > input {
        position: absolute;
        top: 0;
        opacity: 0;
        width: 180px;
        height: 180px;
        cursor: pointer;
      }
    }
  }
  .avatar-action.plan-setting {
    margin-bottom: 0;
  }

  h3 {
    font-size: 20px;
    font-weight: normal;
    color: #000;
    margin-top: 35px !important;
  }

  /**
  ***
  *** Plans Slider
  ***
  **/
  .plan-slider {
    max-width: 470px;
    width: 100%;
    margin: 0 auto;
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            img {
              display: initial;
            }
            .basic {
              background: #fff;
              min-height: 800px;
              border-radius: 0.75rem;
              outline: none;
              &-header {
                background: linear-gradient(135deg, #4b478d 0%, #cf2d86 100%);
                min-height: 412px;
                border-radius: 0.75rem;
                float: left;
                padding: 1rem 5.2rem;
                color: #fff;
                position: relative;
                .goto-back {
                  position: absolute;
                  top: 55px;
                  left: 23px;
                  background: transparent;
                }
                h2 {
                  font-size: 35px;
                  font-weight: 700;
                  line-height: 42px;
                  text-align: center;
                  margin-bottom: 85px;
                }
                h3 {
                  font-size: 23px;
                  font-weight: 700;
                  line-height: 28px;
                  text-align: center;
                }
              }
              &-footer {
                float: left;
                padding: 0 4rem;
                text-align: center;
                h3 {
                  font-size: 23px;
                  font-weight: 700;
                  line-height: 28px;
                  margin: 24px 0 95px;
                }
                button {
                  font-family: "Lato", sans-serif;
                  font-size: 23px;
                  font-weight: 700;
                  line-height: 28px;
                  background-color: transparent;
                  border: 0;
                  display: block;
                  margin: 45px auto 0;
                  outline: none;
                  cursor: pointer;
                }
              }
            }
            .gold {
              background: #fff;
              min-height: 800px;
              border-radius: 0.75rem;
              outline: none;
              &-header {
                background: linear-gradient(136deg, #fbd604 0%, #e67f15 100%);
                min-height: 412px;
                border-radius: 0.75rem;
                float: left;
                padding: 1rem 4rem;
                color: #000;
                position: relative;
                .goto-back {
                  position: absolute;
                  top: 55px;
                  left: 23px;
                  background: transparent;
                }
                h2 {
                  font-size: 35px;
                  font-weight: 700;
                  line-height: 42px;
                  text-align: center;
                  margin-bottom: 30px;
                }
                h3 {
                  font-size: 23px;
                  font-weight: 700;
                  line-height: 28px;
                  text-align: center;
                  margin-bottom: 17px;
                }
              }
              &-footer {
                float: left;
                padding: 0 4rem;
                text-align: center;
                h3 {
                  font-size: 23px;
                  font-weight: 700;
                  line-height: 28px;
                  margin: 24px 0 105px;
                }
                .btn-starter-free {
                  width: 254px;
                  height: 79px;
                  border-radius: 40px;
                  background: linear-gradient(135deg, #4b478d 0%, #cf2d86 100%);
                  font-family: "Lato", sans-serif;
                  font-size: 19px;
                  font-weight: 700;
                  line-height: 23px;
                  text-align: center;
                  border: 0;
                  color: #fff;
                  margin-top: 75px;
                  margin-bottom: 55px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .slick-prev,
      .slick-next {
        top: unset;
        bottom: 0;
        transform: translate(0, -20px);
        z-index: 1;
        width: 44px;
        height: 44px;
        background-color: #000;
        border-radius: 50%;
        &.slick-disabled {
          &::before {
            cursor: not-allowed;
          }
        }
      }
      .slick-prev {
        left: 20px;
        &:before {
          content: "";
          background-image: url(assets/images/previous.svg);
          display: inline-block;
          width: 45px;
          height: 45px;
        }
      }
      .slick-next {
        right: 20px;
        &:before {
          content: "";
          background-image: url(assets/images/next.svg);
          display: inline-block;
          width: 45px;
          height: 45px;
        }
      }
    }
  }

  /**
  ***
  *** Drop Card
  ***
  **/
  .drop-card {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.15);
    background-blend-mode: hard-light;
    border-radius: 0;
    .gift-header {
      padding-bottom: 21px;
    }
    .gift-body {
      background-color: rgba(0, 0, 0, 0.75);
    }
    .gift-footer {
      padding: 27px 12px;
      text-align: center;
      a {
        text-decoration: none;
        font-size: 15px;
        line-height: 17px;
        color: #fff;
        width: 100%;
        display: inline-block;
        padding: 17px 0;
      }
    }
  }

  .card-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;

    a {
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      text-decoration: none;
      color: #fff;
      font-family: "Lato", sans-serif;
    }
  }

  .card-measure {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 42px;
    .card-col {
      flex: 0 0 50%;
      width: 50%;
    }
    p {
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      color: #fff;
      margin: 0;
    }
    h3 {
      font-size: 35px;
      font-weight: 400;
      line-height: 42px;
      color: #fff;
      margin: 0;
    }
    .text-right {
      text-align: right;
    }
    .counter {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      .group-button {
        display: flex;
        align-items: center;
        flex-flow: column;
        margin-left: 8px;

        button {
          padding: 3px;
          margin: 0;
          background-color: transparent;
          border: 0;
          color: #fff;
          outline: 0;
          height: auto;
          width: 30px;
          min-width: auto;
          cursor: pointer;
          svg {
            font-size: 1.25rem;
          }
        }
      }
    }
  }

  .drop-location {
    text-align: center;

    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      margin: 40px 0 40px;
      color: #fff;
    }

    .location-map {
      position: relative;
      text-align: justify;
      height: 440px;
      width: 400px;
      display: flex;
      margin: 0 auto;
    }
  }

  /**
  ***
  *** Existing Campaigns
  ***
  **/
  .campaigns-wrapper {
    max-width: 420px;
    margin: 50px auto 0;
    .campaigns {
      &-card {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.15);
        background-blend-mode: hard-light;
        box-shadow: 0px 2px 4px rgba(100, 100, 100, 0.5);
        border-radius: 0;
        .gift-body {
          background-color: rgba(0, 0, 0, 0.75);
          min-height: 75px;
          p {
            font-size: 41px;
            line-height: 49px;
          }
        }
        .gift-footer {
          padding: 52px 12px;
          text-align: center;
        }
      }
      &-move {
        margin: 39px 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        a {
          font-family: "Lato", sans-serif;
          font-size: 25px;
          font-weight: 400;
          line-height: 30px;
          color: #fff;
          text-decoration: none;
        }
      }
      &-status {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        p,
        a {
          font-family: "Lato", sans-serif;
          font-size: 25px;
          font-weight: 400;
          line-height: 30px;
          text-decoration: none;
          color: #fff;
          text-align: left;
          display: inline-flex;
        }
        button {
          font-family: "Lato", sans-serif;
          font-size: 25px;
          font-weight: 400;
          line-height: 30px;
          text-decoration: none;
          color: #fff;
          text-align: left;
          display: inline-flex;
          text-transform: none;
          padding: 0;
        }
        p {
          margin: 0;
        }
      }
      &-list-table {
        margin-bottom: 60px;
        table {
          width: 100%;
          tr {
            td {
              font-family: "Lato", sans-serif;
              font-size: 25px;
              font-weight: 400;
              line-height: 30px;
              color: #fff;
              padding: 30px 0;
              width: 50%;
              &:last-child {
                text-align: right;
              }
              &:first-child {
                text-align: left;
              }
              &.notes {
                font-family: "Helvetica", sans-serif;
                font-size: 21px;
                padding-bottom: 0;
              }
            }
          }
        }
      }
      &-action {
        .MuiButton-root {
          font-family: "Lato", sans-serif;
          font-size: 25px;
          font-weight: 700;
          line-height: 30px;
          color: #fff;
          text-transform: capitalize;
          padding: 0;
          margin: 0 0 40px;
          background: transparent;
        }
      }
    }
  }
  .campaign-list-area {
    min-height: 100vh;
    background-color: #fff;
  }

  /**
  ***
  *** Pedding Approval
  ***
  **/

  .button-link {
    background-color: transparent;
    border: 0;
    outline: 0;
    font-size: 22px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
  }
}

/**
*****
*** Drop Gold a Card
*****
**/

.drop-card-container {
  text-align: center;
  width: 100%;
  .card-title {
    font-size: 34px;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 45px;
    max-width: 360px;
  }
}

.drop-card-wrapper {
  text-align: center;
  width: 100%;
  .MuiList-root {
    @media screen and (max-width: 767px) {
      height: calc(100vh - 100px);
    }
    .MuiListItem-root {
      &:last-child {
        margin-bottom: 0;
      }
      img {
        + img {
          width: 70px;
          height: 70px;
          @media screen and (max-width: 767px) {
            width: 30px;
            height: 30px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 35px;
        img {
          width: 140px;
        }
      }
    }
  }
}

.slider {
  .rangeslider {
    height: 3px;
    .rangeslider__fill {
      background-color: #000;
    }
    .rangeslider__handle {
      width: 20px;
      height: 20px;
      background-color: #000;
      margin-top: -1px;
      outline: 0;
      .rangeslider__handle-tooltip {
        display: none;
      }
      &:after {
        display: none;
      }
    }
  }
  .value {
    margin-bottom: 39px;
    text-align: right;
    font-size: 18px;
    font-weight: bold;
  }
}

.gold-map-view {
  position: relative;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 1279px) {
    height: calc(100vh - 57px);
  }
  .map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: inline-block;
  }
  .search-area {
    position: absolute;
    top: 40px;
    left: 40px;
    display: inline-flex;
    max-width: 420px;
    width: 100%;
    @media screen and (max-width: 767px) {
      left: 0;
      margin: 0 auto;
      position: fixed;
      top: 90px;
      justify-content: center;
      align-items: center;
    }
    .addCardForm {
      display: inline-flex;
      width: 100%;
      @media screen and (max-width: 767px) {
        display: inline-flex;
        width: auto;
      }
      .custom-input {
        margin: 0;
        width: 100%;
        padding: 13px 46px;
        background: #fff;
        color: #0a0a0a;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        @media screen and (max-width: 767px) {
          padding: 13px 26px;
        }
        input {
          @media screen and (max-width: 767px) {
            text-align: left;
          }
          color: #0a0a0a;
          &::placeholder {
            color: #0a0a0a;
          }
          &::-webkit-input-placeholder {
            color: #0a0a0a;
          }
          &::-ms-input-placeholder {
            color: #0a0a0a;
          }
        }
      }
    }
  }
}

.gold-right-drawer {
  .MuiPaper-root {
    max-width: 500px;
    width: 100%;
    background-color: #1c1524;
    .close {
      position: absolute;
      right: 0;
      min-width: auto;
      background-color: transparent;
      z-index: 1;
      svg {
        fill: #fff;
      }
    }
  }
  .button-gradiant {
    height: 58px;
    border-radius: 2rem;
    background: #af1f70;
    background: linear-gradient(90deg, #af1f70 0%, #333fc2 100%);
    font-size: 22px;
    text-transform: capitalize;
    font-family: "Lato", sans-serif;
    border: solid 4px #fff;
    line-height: normal;
    margin: 25px 0;
    padding: 6px 12px;
    min-width: 150px;
    img {
      margin-right: 1rem;
    }
  }
}

.people-ratio {
  .MuiTabs-root {
    padding: 0 40px;
    .MuiTabs-fixed {
      .MuiTabs-flexContainer {
        justify-content: space-between;
        align-items: center;
        .MuiTab-root {
          font-family: "Lato", sans-serif;
          font-size: 22px;
          color: #ffff;
          opacity: 1;
          text-transform: capitalize;
          min-width: 33.33%;
          text-align: center;
        }
        + .MuiTabs-indicator {
          background-color: #fff;
        }
      }
    }
  }
  .ratio-tab-area {
    .MuiBox-root {
      padding: 0 40px;
      height: calc(100vh - 50px);
      overflow-y: auto;
      h3 {
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
        font-family: "Lato", sans-serif;
        font-size: 23px;
        font-weight: 700;
        line-height: 27px;
        color: #fff;
      }
      h4 {
        margin-top: 0;
        margin-bottom: 0.875rem;
        font-family: "Lato", sans-serif;
        font-size: 22px;
        font-weight: 700;
        line-height: 27px;
        color: #fff;
      }
      .MuiList-root {
        padding: 0;
        .MuiListItem-root {
          padding: 0 0 0.675rem;
          .MuiListItemAvatar-root {
            width: 100%;
            > div {
              display: flex;
              align-items: center;
              width: 100%;
              .MuiAvatar-root {
                width: 63px;
                height: 63px;
              }
              progress {
                width: calc(100% - 140px);
                height: 23px;
                margin-left: 1rem;
                background: #ffffff;
                position: relative;
                &::-webkit-progress-bar {
                  background-color: #1c1524;
                }
                &::-webkit-progress-value {
                  background: #ffffff;
                  border-radius: 15px;
                  transition: all 0.3s ease-in-out;
                }
                &::after {
                  content: attr(value);
                  position: absolute;
                  right: -55px;
                  top: 0;
                  color: #fff;
                  font-family: "Lato", sans-serif;
                  font-size: 22px;
                  font-weight: 700;
                  line-height: 27px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.create-gold-card,
.confirm-gold-card,
.preview-gold-card {
  padding-top: 50px;
  h2 {
    font-family: "Lato", sans-serif;
    font-size: 27px;
    font-weight: 400;
    line-height: 32px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    a {
      color: #fff;
      text-decoration: none;
    }
  }

  .drop-card {
    max-width: 370px;
    margin: 0 auto;
    .gift-footer {
      a {
        padding: 0;
      }
    }
  }
  .card-action {
    max-width: 370px;
    margin: 35px auto 0;
    flex-flow: column;
    .card-change {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 39px;

      .button {
        width: 127px;
        height: 56px;
        border-radius: 28px;
        background: #fff;
        color: #000;
        font-family: "Lato", sans-serif;
        font-size: 29px;
        font-weight: 400;
        line-height: 34px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px;
        &.disabled {
          background-color: #979797;
        }
      }
    }
    .content-text {
      font-family: "Lato", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      margin-top: 0;
      margin-bottom: 1rem;
      color: #fff;
    }
    .content-link {
      font-family: "Lato", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 53px;
      color: #fff;
      text-decoration: none;
      width: 100%;
    }
    .content-list {
      font-family: "Lato", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0;
    }
    .move-to-next {
      margin-top: 130px;
      margin-bottom: 25px;
      .button-gradiant {
        margin: 0;
      }
      .MuiButtonGroup-root {
        margin-right: 1rem;
        .MuiButton-root {
          background-color: #000;
          border-color: #000;
          color: #fff;
          font-family: "Lato", sans-serif;
          font-size: 29px;
          font-weight: 400;
          line-height: 35px;
          &:first-child {
            border-radius: 2rem;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          &:last-child {
            border-radius: 2rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}

.confirm-gold-card,
.preview-gold-card {
  padding-top: 39px;
  h2 {
    justify-content: center;
  }
  .card-action {
    .content-text-available,
    .content-text-time {
      font-family: "Lato", sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      margin-top: 0;
      margin-bottom: 22px;
      color: #fff;
    }
    .content-text {
      margin-top: 40px;
      text-align: center;
    }
    .content-link {
      text-align: center;
    }
    .move-to-next {
      margin-top: 50px;
      .button-gradiant {
        border: 0;
        max-width: 250px;
        width: 100%;
        font-weight: bold;
        &.button-white {
          background: #fff;
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          margin-bottom: 2.25rem;
          color: #000;
        }
      }
    }
  }
}

.preview-gold-card {
  .card-action {
    max-width: 440px;
    .card-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      p {
        font-family: "Lato", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: normal;
        margin-top: 0;
        margin-bottom: 24px;
        color: #fff;
      }
    }
    .content-text {
      margin-top: 22px;
    }
  }
}

.card-offer-container {
  width: 500px;
  margin: 0 auto;

  .card-offer-content {
    border-radius: 40px;
    background-color: #fff;
    // overflow: hidden;
    // margin-top: -50px;

    .card-offer-header {
      padding: 10px;
      font-size: 26px;
      text-align: center;
    }

    .card-section {
      padding-bottom: 40px;

      button {
        font-size: 16px;
        font-weight: bold;
        background-color: transparent;
        border: none;
        outline: none;
        margin-top: 30px;
        cursor: pointer;
      }
    }
    .card-section.review {
      padding-bottom: 15px;

      > p {
        text-align: center;
        margin: 0;
      }

      .short-text {
        margin-top: 20px;
      }
    }
    .card-section.review.approval-screen {
      margin-top: 30px;
    }
  }

  .bottom-section {
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 0 0 40px 40px;
  }
  .bottom-section.edit-offer-details {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.card-offer-container.approval-screen {
  margin-top: 30px;
}

.gift-card {
  height: 230px;
  width: 400px;
  background-color: #ffffff;
  margin: 0 auto;
  position: relative;

  .create-a-title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 50px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);

    input {
      background-color: transparent;
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 26px;
      caret-color: #fff;
      color: #fff;
    }

    input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #fff;
    }
    input::-moz-placeholder {
      /* Firefox 19+ */
      color: #fff;
    }
    input:-ms-input-placeholder {
      /* IE 10+ */
      color: #fff;
    }
    input:-moz-placeholder {
      /* Firefox 18- */
      color: #fff;
    }
  }

  .view-title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 75px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: #fff;
      text-align: center;
      font-size: 26px;
      margin: 0;
      margin-left: 0.3rem;
    }
  }

  .card-logo {
    position: absolute;
    height: 50px;
    width: 50px;
    left: 15px;
    top: 15px;
    background-color: #cfcfcf;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
      position: absolute;
      right: -4px;
      top: 0;
      background-color: black;
      height: 20px;
      width: 21px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(0.7);
      cursor: pointer;
    }

    > p {
      margin: 0;
      position: absolute;
      font-size: 11px;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-decoration: underline;
      line-height: 14px;
      cursor: pointer;
    }

    img {
      height: 30px;
      width: 30px;
    }
  }

  .card-expiry {
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 13px;
      font-weight: 600;
      text-align: center;
      margin: 0;
    }
  }

  .card-description {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 75px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;

    p {
      font-size: 18px;
      text-align: center;
      margin-top: 10% !important;
      height: 100%;
      margin-bottom: 7%;
    }

    textarea {
      background-color: transparent;
      border: none;
      outline: none;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      text-align: center;
      font-size: 18px;
      caret-color: #fff;
      color: #fff;
      font-family: "Lato", sans-serif !important;
      resize: none;
      overflow-y: hidden;

      &::placeholder {
        color: #fff;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .card-description::-webkit-scrollbar {
    display: none;
  }

  .card-description.light-background {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .card-checkout-section {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;

      span {
        color: #ff05ef;
      }
    }
    a {
      align-self: center;
      font-size: 18px;
    }
  }

  .tap-to-view-details {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
      font-size: 14px;
      color: #fff;
      text-align: center;
      margin-top: 14px !important;
      padding-top: 0 !important;
    }
  }
}
.gift-card.shadow {
  box-shadow: 0 0 5px #444;
}

.card-front-options-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .card-front-option {
    width: 175px;
    height: 210px;
    background-color: #fff;
    border: 1px solid #bebebe;
    cursor: pointer;
    display: flex;
    // align-items: center;
    justify-content: center;

    .card-front-content-container {
      width: 145px;
      // height: 200px;
      display: flex;
      flex-direction: column;

      .img-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .border {
          border: 2px solid #000;
          width: 117px;
          height: 85px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
        }

        img {
          height: 67px;
        }
      }

      p {
        margin: 0;
        padding-bottom: 15px;
        text-align: center;
        &:last-child {
          color: red;
        }
      }
      
    }
  }
}

.card-back-options-container-1 {
  // border-radius: 40px !important;
}

.card-review-container {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 15px !important;
}

.gift-card-footer-button {
  font-size: 22px;
  background-color: #fff;
  padding: 15px 50px;
  border-radius: 30px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: #dddddd;
  }
}

.gift-card-number-input {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  height: 60px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 22px;
  width: 100%;
  font-weight: bold;
  cursor: pointer;
}

.offer-input-container {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  padding-left: 30px;
  padding-right: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  label {
    display: inline-block;
    flex: 2;
    font-size: 20px;
  }

  .input-area {
    flex: 1;
    display: flex;
    align-items: center;

    span {
      font-size: 22px;
      margin-right: 5px;
      font-weight: bold;
    }
  }
}
.offer-input-container.expiry-date-input-container {
  overflow: visible;
}
#expiry-date-input {
  font-size: 18px;
}

.input-control {
  position: absolute;
  top: 0;
  right: 10px;
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .input-control-caret {
    height: 25px;
    cursor: pointer;
  }
}

.expiry-date-input-container {
  padding-right: 60px;

  label {
    flex: 1;
  }

  .input-area {
    span {
      margin-right: 0px !important;
      margin-left: 5px !important;
    }
  }

  .calendar-input-icon {
    border: 2px solid #d8d8d8;
    height: 30px;
    width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
  }

  .input-control {
    right: 20px;
  }
}

.description-input-container {
  padding: 0 !important;

  textarea {
    background: transparent;
    border: none;
    outline: none;
    resize: none;
    width: 100%;
    padding: 20px;
    padding-left: 30px;
    font-family: "Lato", sans-serif !important;

    &::placeholder {
      color: #000;
      font-size: 15px;
    }
  }
}

.card-back-options-container-2 {
  padding-top: 40px !important;
}

.step-2-input-container {
  display: flex;
  margin-bottom: 20px;

  .label-container {
    width: 115px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    label {
      font-size: 14px;
    }
  }

  .right-section {
    flex: 1;

    input {
      height: 60px;
      background: #fff;
      border: none;
      outline: none;
      font-size: 14px;
      width: 100%;
      border-radius: 10px;
      padding-left: 15px;
      box-sizing: border-box;
    }

    ul {
      padding: 0;
      list-style: none;
      margin-bottom: 0;

      li {
        margin-bottom: 10px;
        p {
          margin: 0;
          font-size: 11px;
          font-style: italic;
        }
      }
    }
  }
}

.card-details-item {
  display: flex;
  background-color: #fff;
  padding-left: 35px;
  padding-right: 25px;
  margin-top: 6px;
  height: 50px;
  align-items: center;
  text-align: left;

  p {
    margin: 0;
    flex: 1;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
  }

  button {
    background: none;
    color: #000;
    font-size: 15px;
    border: none;
    outline: none;
    font-weight: bold;
    cursor: pointer;
  }
}

.congrats-emoji {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 30px;

  img {
    width: 70px;
    align-self: center;
  }
}

.card-offer-header.final {
  margin-bottom: 35px;
}

.final-review-text {
  padding-top: 10px;
  padding-bottom: 60px;

  p {
    text-align: center;
    font-size: 28px;
  }
}

.gift-input-hint-text {
  font-size: 13px;
  margin: 0;
  margin-bottom: 5px;
  margin-top: 25px;
}

.verify-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  .verify-checkbox-container {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  p {
    flex: initial;
    font-size: 16px;
    // margin-top: 0;
  }
}

.chest-campaign-checkbox {
  margin: 0;
  .verify-checkbox-container {
    width: auto;
    margin-left: 0px;
  }
  p {
    font-size: 18px;
    font-weight: bold;
  }
}

.create-new-campaign {
  .verify-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-right: 0;
  
    .verify-checkbox-container {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0;
    }
  
    p {
      flex: initial;
      font-size: 20px;
      margin: 0;
    }
  }
}

.drop-box-container {
  background-color: #fff;
  width: 350px;
  border-radius: 25px;
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: auto;
  margin-top: 50px;

  > img {
    width: 120px;
    height: 140px;
  }

  .tooltip-container {
    position: absolute;
    height: 75px;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 65px;
      position: absolute;
      right: 10px;
      bottom: 5px;
    }
    p {
      margin: 0;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.middle-content-area {
  width: 100%;
  max-width: 500px;
  margin: auto;
  border-radius: 39px;

  > p {
    font-size: 26px;
    text-align: center;
    margin-bottom: 36px;
    font-weight: bold;
  }

  .bottom-button {
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
  }
}

.middle-content-area.transparent {
  background-color: transparent;

  p {
    margin-top: 0;
  }
}

.middle-content-area.white {
  background-color: #fff;

  > p {
    padding-top: 36px;
  }
}

.create-new-campaign {
  > h2 {
    text-align: center;
  }

  .create-campaign-card-con {
    margin-top: 30px;
  }
}

.gift-card-navigator {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 400px;
  width: 100%;
  margin: 15px auto;

  button {
    background-color: transparent;
    font-size: 20px;
    font-weight: bold;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

.white-card {
  background-color: white;
  border-radius: 15px;
}

.create-campaign-quantity-section {
  padding: 15px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .available-section {
    flex: 1;

    h3,
    p {
      margin: 0;
      text-align: left;
    }

    p {
      font-size: 30px;
    }
  }
  .set-quantity-section {
    flex: 1;

    h3 {
      margin: 0;
      display: inline-block;
    }

    p {
      text-align: right;
      margin-bottom: 0;
    }

    input {
      font-size: 30px;
      text-align: right;
    }
  }

  .set-quantity-controls {
    width: 20px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 10px;

    button {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      transition: 0.3s ease-in-out;

      :hover {
        color: #c62f87;
        transition: 0.3s ease-in-out;
      }
    }
  }
}

.transparent-input {
  background-color: transparent;
  border: none;
  outline: none;
  display: block;
  width: 100%;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
}

.transparent-button {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.select-available-country {
  padding: 20px 30px;
  padding-bottom: 40px;
  margin-top: 20px;

  h3 {
    margin: 0;
    margin-bottom: 30px;
  }
}

.toggler-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.country-badge {
  padding: 10px 15px;
  font-size: 18px;
  display: inline-block;
  margin-right: 15px;
  border-radius: 28px;
  min-width: 70px;
  text-align: center;
  border: 2px solid #000;
  transition: background 0.2s ease-in-out;
  cursor: pointer;
  margin-bottom: 0.5rem;

  span {
    color: #000;
    font-weight: 600;
  }

  &:hover {
    background-color :#e0e0e0;
    transition: background 0.2s ease-in-out;

    // span {
    //   color: #fff;
    // }
  }
}
.country-badge.selected {
  background-color: #000;
  span {
    color: #fff;
  }
}

.select-available-city {
  margin-top: 20px;
  padding: 20px 50px;
  padding-bottom: 40px;
  min-height: 250px;

  .search-city-input {
    height: 45px;
    width: calc(100% - 40px);
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: #e0e0e0;
    font-size: 18px;
    padding: 0 20px;
    font-weight: 600;
    margin-top: 1rem;

    &::placeholder {
      color: #000;
    }
  }
}

.selected-cities-container {
  margin-top: 20px;
}

.city-item {
  padding: 13px 20px;
  background-color: #e0e0e0;
  position: relative;
  display: inline-block;
  border-radius: 8px;
  margin-right: 15px;
  margin-bottom: 15px;

  p {
    margin: 0;
    text-transform: capitalize;
  }

  span {
    position: absolute;
    background-color: #000;
    height: 17px;
    width: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    right: 0;
    top: 0;
    font-size: 10px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
      background-color: red;
      transition: background 0.2s ease-in-out;
    }
  }
}

.city-search-result-container {
  position: absolute;
  top: 0px;
  left: 10px;
  background-color: #fff;
  width: calc(100% - 20px);
  z-index: 1;
  box-shadow: 0 1px 4px #77737c;
  border-radius: 0 0 3px 3px;
}

.city-search-result-item {
  border-bottom: 1px solid #979797;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  cursor: pointer;

  .map-icon-container {
    padding: 10px;
  }

  p {
    margin: 0;
    color: #363645;
    font-size: 14px;

    span {
      color: #c8c8c8;
    }
  }
}

.select-gender {
  margin-top: 20px;
  padding: 20px 50px;
}
.select-gender-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -10px;

  span {
    color: #858585;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
  }

  span.selected {
    color: #000;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.gradiant-button {
  border: none;
  outline: none;
  padding: 20px 50px;
  color: #fff;
  font-size: 22px;
  border-radius: 40px;
  text-decoration: none;
  background: linear-gradient(165deg, #4b478d, #cf2d86);
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  }
}

.preview-campaign {
  h3 {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
  }
  .preview-quantity-section {
    padding: 0 50px;
    margin-top: 30px;
  }
  .preview-location-text {
    margin-top: 80px;
    text-align: center;
  }
  .preview-gender-text {
    text-align: center;
    margin: 20px auto;
  }
}

.complete-button-container {
  margin-top: 80px;
  margin-bottom: 30px;
  text-align: center;
  padding-bottom: 30px;
}

.go-back-button {
  font-size: 21px;
  background-color: #fff;
  border-radius: 26px;
  width: 136px;
  height: 52px;
  margin-left: 40px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  font-weight: bold;
  display: block;

  &:hover {
    background-color: #dddddd;
  }
}

.go-back-button-container {
  text-align: center;
  margin-bottom: 30px;
}

.existing-campaign-edit-section-1 {
  margin-bottom: 25px;

  display: flex;
  flex-direction: row;

  .edit-status-container {
    flex: 2;
  }
  .edit-sec-1-divider {
    flex: 0.5;
  }
  .right-container {
    flex: 2;
  }

  .campaign-edit-con {
    padding: 15px;

    h3,
    p {
      margin: 0;
      display: inline-block;
      font-size: 20px;
    }

    > h3 {
      padding-top: 13px;
      padding-bottom: 13px;
    }
  }

  .status-change-button {
    margin-left: -5px;
    margin-top: 12px;
  }

  .campaign-edit-con-right {
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .right-edit-bottom-con {
    margin-top: 10px;
  }

  .right-edit-button {
    h3,
    p {
      font-size: 20px;
      margin: 13px 0;
    }
  }
  .action-button-no-left-gap {
    padding-left: 0;
  }
}

.existing-campaign-edit-section-2 {
  margin-bottom: 25px;

  .bulk-edit-con {
    display: flex;
    flex-direction: row;
    padding: 15px;
    padding-top: 25px;
    padding-bottom: 0;

    h3,
    p {
      margin: 0;
      font-size: 20px;
    }

    .bulk-edit-grid-col-3 {
      flex: 3;
    }

    .bulk-edit-grid-col-2 {
      flex: 2;
    }

    .bulk-edit-item {
      margin-bottom: 35px;
      input {
        border: none;
        border-bottom: 2px solid;
        margin-left: 0.2rem;
        width: 50%;
        font-weight: bold;
        font-size: 18px;
        &:focus {
          outline: none;
        }
      }
      .switch-input {
        display: inline;
        width: 30%;
        margin-left: 2rem;
        color: #000;
      }
    }
  }
}
.category-list {
  margin-top: 5%;
}
.category-items {
  input {
    border: none;
    border-bottom: 2px solid;
    margin-left: 0.2rem;
    width: 60%;
    // font-weight: bold;
    font-size: 18px;
    &:focus {
      outline: none;
    }
  }
}
.contest-date-picker {
  position: absolute;
  top: -130%;
  left: -15%;
}

.view-analytics-button-con {
  margin-top: 25px;
  margin-bottom: 25px;

  p {
    text-align: center;
  }

  .view-analytics-button {
    margin: 25px auto;
    font-size: 20px;
    font-weight: bold;
  }
}

.base-top-gap {
  margin-top: 64px;
  margin-bottom: 64px;
}
.base-top-gap.base-top-gap-by-bar {
  margin-top: 0;
}

.top-action-bar {
  padding: 15px;
}

.table-row-min-height {
  height: 100px;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-personal-input {
  display: flex;
  padding: 0 15px;
  margin-bottom: 15px;

  p,
  input,
  button {
    font-size: 16px;
    margin-top: 18px;
    margin-bottom: 18px;
  }

  input,
  p.label {
    margin-left: 5px;
  }

  .left {
    flex: 1;

    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dual-value {
    display: flex;
    flex: 1;
    p {
      flex: 1;
      margin: 0;
    }
  }
}

.pricing-button-con {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
  cursor: pointer;

  p {
    color: #fff;
    margin-top: 18px;
    margin-bottom: 18px;
  }
}

.no-border {
  border: none !important;
}

.plan-box-container {
  background-color: #fff;
  width: 642px;
  margin: 0 auto;
  transform: translateY(-25px);
  box-shadow: 0px 2px 4px #999;

  .bar {
    height: 70px;
    background-color: #d72d83;
    margin-top: 50px;

    button {
      position: absolute;
      height: 40px;
      width: 40px;
      right: 15px;
      top: 15px;
      background-color: #fff;
      color: #000;
    }
  }

  .content-area {
    display: flex;
    flex-direction: row;
  }

  .left,
  .right {
    flex: 1;
  }

  .plan-type-box {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d8d8d8;
    margin: 20px 25px;

    p {
      font-size: 25px;
    }
  }
  .plan-type-box.blue {
    background-color: #6ea6f8;
  }
  .divider {
    width: 2px;
    background-color: #000;
  }

  .plan-desc-item-con {
    margin: 70px 0;

    .plan-desc-item {
      padding: 0 60px;

      p {
        font-size: 18px;
      }
    }

    .plan-desc-item.first-item {
      margin-bottom: 30px;
    }

    .plan-desc-item.selected {
      background-color: #e0ffe7;
      padding: 10px 60px;
    }
  }

  .select-plan-con {
    margin-bottom: 30px;

    button {
      width: 190px;
      height: 64px;
      border-radius: 11px;
      background-color: #d8d8d8;
      border: none;
      outline: none;
      font-size: 18px;
      cursor: pointer;
    }

    button.selected {
      background-color: #0065f7;
      color: #fff;
    }
  }
}

.right-content-area {
  margin-left: 325px;
}
.right-content-area.right-content-area-menu-open {
  margin-left: 325px;
}
@media screen and (max-width: 1279px) {
  .right-content-area {
    margin-left: 0;
  }
}

.no-offers-container {
  border-radius: 39px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 80px;
  cursor: pointer;

  p {
    text-align: center;
    font-size: 24px;
    padding: 0;
    margin: 0;
  }

  > p {
    margin: 80px auto !important;
  }

  .new-offer-card {
    border-radius: 39px;
    border: 1px solid #979797;
    position: relative;
    height: 300px;
    margin-bottom: 100px;

    > p {
      position: absolute;
      width: 100%;
      left: 0;
      top: 190px;
    }
  }

  .plus-icon {
    width: 54px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
    }
  }
}

.sidebar-menu-icon {
  position: absolute;
  display: none;
  top: 15px;
  height: 50px;
  width: 50px;
  background-color: #fff;
  left: 0;
  transition: 0.2s ease-in-out;
  z-index: 1;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
}
.sidebar-menu-icon.sidebar-open {
  left: 326px;
}

@media screen and (max-width: 1279px) {
  .sidebar-menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sidebar-menu-con.show {
  left: 0;
  width: 325px;
}
.sidebar-menu-con {
  border-right: none !important;
}

.view-details-btn {
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  border: none;
  outline: none;
  margin-top: 30px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}

.no-background {
  background-color: transparent !important;
}

.display-inline-block {
  display: inline-block;
}

.display-none {
  display: none;
}

.settings-container {
  position: relative;
}

.my-modal {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tab-item-title {
  font-size: 20px !important;
  color: #000 !important;
  font-family: "Lato", sans-serif !important;
  text-transform: capitalize !important;
}
.tab-item-title.Mui-selected {
  font-weight: bold;
}

.MuiTabs-indicator {
  background-color: transparent !important;
}

.MuiTabs-flexContainer {
  border-bottom: 2px solid #000;
}

.MuiBox-root {
  padding: 0 !important;
}

.table-row-bg-white {
  background-color: #fff !important;
}

.MuiTabs-flexContainer {
  background-color: #f0f0f0;
}

.approval-actions-container {
  margin-top: 30px;

  .approval-actions-btn-con {
    margin-bottom: 30px;
  }

  .accept-reject-button {
    font-size: 22px !important;
    padding: 15px 50px;
    border-radius: 30px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-weight: normal;
  }
  .accept-reject-button.active {
    background-color: #fff;
  }
  .accept-reject-button.in-active {
    background-color: transparent;
  }
}

.reject-note-input {
  background-color: #fff;
  resize: none;
  margin: 30px auto;
  margin-top: 0;
  width: 360px;
  border-radius: 30px;
  font-family: "Lato", sans-serif !important;
  font-size: 18px;
  padding: 20px;
}

.approval-actions-bottom-btn-con {
  display: flex;
  justify-content: space-between;
}
.MuiInputBase-root {
  color: #fff !important;
}

.card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.3rem;
}
.MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 4px 8px !important;
}
@media only screen and (max-width: 767px) {
  .makeStyles-layout-14 {
    width: 90% !important;
    height: auto !important;
  }
  .makeStyles-layout-34 {
    width: 100% !important;
    height: 100% !important;
    overflow-y: auto;
  }
  .makeStyles-paper-13 {
    height: auto !important;
    padding: 20px 15px !important;
  }
  .makeStyles-form-7 {
    margin-top: 0 !important;
  }
  .MuiGrid-container {
    overflow-y: auto !important;
  }
  .makeStyles-layout-2 {
    width: 90% !important;
  }
}
.MuiFormControl-marginNormal {
  // margin-top: 0 !important;
}
.tooltip {
  margin-left: 0.25rem;
  margin-bottom: 0.4rem;

  vertical-align: middle;
}

.no-offer-container {
  position: absolute;
  left: 50%;
  top: 10%;
  @media only screen and (max-width: 600px) {
    position: inherit;
  }
}

.h3 {
  font-size: 20px;
  font-family: "Lato", sans-serif !important;
  font-weight: bold;
}
.new-category {
  width: 100%;
  float: right;
}
.category-add-button {
  position: absolute;
  bottom: 0;
}
.category-add-input {
  color: #000 !important ;
}
.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  color: #000 !important ;
}

.offersSlider {
  .slick-prev {
    left: 25px;
    top: 343px;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    background: #D8D8D8;
    &:before {
      content: url("../src/assets/icons/sliderArrow.svg");
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
  .slick-next {
    right: 25px;
    top: 343px;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    background: #D8D8D8;
    &:before {
      content: url("../src/assets/icons/sliderArrow.svg");
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      transform: rotate(180deg);
    }
  }
  .slick-slide {
    display: flex;
    justify-content: center;
  }
}

.offersSlider {
  .slick-prev {
    left: 25px;
    top: 343px;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    background: #D8D8D8;
    &:before {
      content: url("../src/assets/icons/sliderArrow.svg");
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
  .slick-next {
    right: 25px;
    top: 343px;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    background: #D8D8D8;
    &:before {
      content: url("../src/assets/icons/sliderArrow.svg");
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      transform: rotate(180deg);
    }
  }
  .slick-slide {
    display: flex;
    justify-content: center;
  }
}

.offerPageSlider {
  margin-bottom: 60px;
  .slick-prev {
    left: 20px;
    top: 310px;
    width: 135px;
    
    &:before {
      content: "Previous offer";
      display: flex;
      color: #000;
      font-size: 21px;
      font-family: "Lato", sans-serif !important;
      justify-content: center;
      align-items: center;
      height: 30px;
      font-weight: bold;
    }
  }
  .slick-next {
    right: 15px;
    top: 310px;
    width: 100px;
    
    &:before {
      content: "Next offer";
      display: flex;
      color: #000;
      font-size: 21px;
      font-family: "Lato", sans-serif !important;
      justify-content: center;
      align-items: center;
      height: 30px;
      font-weight: bold;
    }
  }
  .slick-slide {
    display: flex;
    justify-content: center;
  }
}

.offerPageSliderM {
  margin-bottom: 30px;
  .slick-prev {
    left: 20px;
    top: 250px;
    width: 135px;
    
    &:before {
      content: "Previous offer";
      display: flex;
      color: #000;
      font-size: 21px;
      font-family: "Lato", sans-serif !important;
      justify-content: center;
      align-items: center;
      height: 30px;
      font-weight: bold;
    }
  }
  .slick-next {
    right: 15px;
    top: 250px;
    width: 100px;
    
    &:before {
      content: "Next offer";
      display: flex;
      color: #000;
      font-size: 21px;
      font-family: "Lato", sans-serif !important;
      justify-content: center;
      align-items: center;
      height: 30px;
      font-weight: bold;
    }
  }
  .slick-slide {
    display: flex;
    justify-content: center;
  }
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.j-center {
  justify-content: center;
}

.a-center {
  align-items: center
}

.campaign {
  &-wrapper {
    background: #fff;
    box-sizing: border-box;
    width: 580px;
    min-height: 982px;
    border-radius: 51px;
    padding: 33px 0 39px 0;
    display: flex;
    flex-direction: column;

    .offer-input-container {
      padding: 0;
      margin: 0 30px;
    }

    .verify-checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-right: 0;
    
      .verify-checkbox-container {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
      }
    
      p {
        flex: initial;
        font-size: 20px;
        margin: 0;
      }
    }
  }

  &-wrapper-offers {
    box-sizing: border-box;
    width: 500px;
    height: 982px;
    border-radius: 51px;
    display: flex;
    flex-direction: column;
  }

  &-header {
    margin: 0;
    font-size: 34px;
  }
  &-blackBTN {
    text-transform: none !important;
    background: #000 !important;
    font-size: 22px !important;
    color: #fff !important;
    padding: 0 53px !important;
    box-sizing: border-box !important;
    height: 63px;
    border-radius: 31px !important;
    margin: 0 auto !important;
  }
  &-bottomBTN {
    background: linear-gradient(131.04deg, #4B478D 13.29%, #CA2E86 100%) !important;
    text-transform: none !important;
    color: #fff !important;
    box-sizing: border-box !important;
    height: 63px;
    border-radius: 31px !important;
    width: 237px !important;
    margin: auto auto 0 auto!important;
    font-size: 22px !important;
  }
}

.MuiSelect-select {
  padding-left: 13px !important;
  &:focus {
    background-color: transparent !important;
  }
}

.MuiInput-underline:after {
  border: none !important;
}

.bold {
  font-weight: bold;
}

.logoutBtn {
  background: white !important;
  padding: 7px 20px !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.country-selector {
  width: 440px;
  @media screen and (max-width: 500px) {
    max-width: 313px;
  }
  background: #ECECEC;
  height: 40px;
  font-size: 22px;
  color: #000 !important;
  text-align: left;
  text-transform: capitalize !important;
}