.scrollableTextArea {
  overflow-y: auto;
}
#inputField {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: red;
}
.card-description textarea {
  background-color: red;
}

@media (max-width: 500px) {

  .card-offer-container {
    width: 375px !important;
  }
  .card-offer-content {
    width: 375px !important;
  }

  .gift-card {
    width: 300px;
    height: 230px;
  }

  .gift-card .card-logo {
    width: 40px;
    height: 40px;
  }
  }