:root {
  --reach-combobox: 1;
}

[data-reach-combobox-popover] {
  background: hsla(0, 100%, 100%, 0.99);
  font-size: 20px;
  z-index: 10;
  border-radius: 8px;
}

[data-reach-combobox-list] {
  list-style: none;
  margin: 0;
  padding: 0;
  user-select: none;
}

[data-reach-combobox-option] {
  cursor: pointer;
  margin: 0;
  padding: 0.5rem 0.5rem;
}

[data-reach-combobox-option][aria-selected="true"] {
  background: hsl(211, 10%, 95%);
}

[data-reach-combobox-option]:hover {
  background: hsl(211, 10%, 92%);
}

[data-reach-combobox-option][aria-selected="true"]:hover {
  background: hsl(211, 10%, 90%);
}

[data-suggested-value] {
  font-weight: bold;
  font-size: 20px;
}