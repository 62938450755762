form {
  width: 100%;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  margin-top: 18px;
}

.addCard {
  font-size: 19px!important;
  font-weight: bold!important;
  text-transform: none!important;
  padding: 6px 8px!important;
}

@media (max-width: 500px) {
  .StripeElement {
    width: 100%;
    margin-top: 12px;
  }
  }